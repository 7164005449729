<template>
  <div class="vx-row">
    <div v-if="$acl.check('school') || $acl.check('admin')" class="vx-card p-6">
      <h5 style="margin-bottom:9px">Pago de Servicios</h5>
      <!-- <span>Listado de pagos únicos de servicio recibidos a través de la aplicación Tiptap (módulo deprecado en junio 2024).</span> -->
      <span>Listado de pagos únicos de servicio recibidos a través de la aplicación Tiptap.</span>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  requests.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : requests.length
                }}
                de {{ requests.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
          <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
          <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
          <date-picker :language="languages.es" v-model="to" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="requests"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import reportModule from "@/store/report/moduleReport.js";
import DatePicker from "vuejs-datepicker";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from './cell-renderer/CellRendererActionsRequestsAdmin.vue';
import * as lang from 'vuejs-datepicker/src/locale/Language';

export default {
  data() {
    return {
      modules: AllCommunityModules,

      from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),

      //Table
      searchQuery: "",
      gridOptions: {},

      languages: lang,

      maxPageNumbers: 6,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      groupHeaderHeight: 150,
      headerHeight: 100,
      columnDefs: [
        {
          headerName: "Id",
          field: "id",
          width: 80,
          filter: false,
        },
        {
          headerName: "Fecha de Pago",
          field: "created_at",
          filter: true,
          width: 180,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Acudiente",
          field: "guardian_name",
          width: 250,
          filter: true,
        },
        {
          headerName: "Estudiante",
          field: "student_name",
          width: 250,
          filter: true,
        },
        {
          headerName: "Descripción",
          field: "note",
          width: 100,
          filter: true,
        },
        {
          headerName: "Servicio",
          field: "name",
          width: 200,
          filter: true,
        },
        {
          headerName: "Monto de Servicio",
          field: "base_amount",
          filter: true,
          width: 140,
          valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount, '$ '),
        },
        {
          headerName: "Cargo por Servicio",
          field: "processing_fee_amount",
          filter: true,
          width: 140,
          valueFormatter: params => this.$options.filters.currencySign(params.data.processing_fee_amount, '$ '),
        },
        {
          headerName: "Total Abonado",
          field: "final_amount_payer",
          filter: true,
          width: 140,
          valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount_payer, '$ '),
        },
      ],

      requests: [],
      // Cell Renderer Components
      components: {
          CellRendererActions,
      },
    };
  },
  components: {
    DatePicker,
    VuePerfectScrollbar,
    VueApexCharts,
    StatisticsCardLine,
    AgGridVue,
    CellRendererActions
  },

beforeMount(){
      this.frameworkComponents = {
    };
},

  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }

    if(this.$acl.check('school'))
      this.loadShoolTable();

    if(this.$acl.check('admin'))
      this.loadAdminTable();

  },

  methods: {
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },
    loadShoolTable() {
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store.dispatch("reportModule/getSchoolServicePayments",{id_establishment: this.$store.state.AppActiveUser.institution.id, dates:dates})
        .then((response) => {

          if (response.data.paid && response.data.paid.length > 0) {
            let s = response.data.paid;
            s.forEach((element) => {
                element.created_at = new Date(element.created_at).toLocaleString("es-AR", this.$localeDateOptions);
            });
            this.requests = s;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadAdminTable() {
      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store.dispatch("reportModule/getAllServicePayments",{dates:dates})
        .then((response) => {

          if (response.data.paid.length > 0) {
            let s = response.data.paid;
            s.forEach((element) => {
                element.created_at = new Date(element.created_at).toLocaleString("es-AR", this.$localeDateOptions);
            });
            this.requests = s;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
  },

  watch: {
        from: function () {
          if (this.$acl.check('school')) { this.loadShoolTable(); }
          if (this.$acl.check('admin')) { this.loadAdminTable(); }
          },
        to: function () {
          if (this.$acl.check('school')) { this.loadShoolTable(); }
          if (this.$acl.check('admin')) { this.loadAdminTable(); }
          },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 6;
      }
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },

  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};

</script>

<style>
.ag-header-cell-label .ag-header-cell-text{
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
</style>
